import { useState, useEffect } from 'react';

function useSortAndStore(initialValue) {
  const [selectedSortOption, setSelectedSortOption] = useState(
    localStorage.getItem('selectedSortOption') || initialValue
  );

  const handleSortOptionChange = (event) => {
    setSelectedSortOption(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem('selectedSortOption', selectedSortOption);
  }, [selectedSortOption]);

  useEffect(() => {
    const storedValue = localStorage.getItem('selectedSortOption');
    if (storedValue) {
      setSelectedSortOption(storedValue);
    }
  }, []);

  return {
    selectedSortOption,
    handleSortOptionChange
  };
}

export default useSortAndStore;
