class DataSorter {
    static sortData(optionValue, data) {
      if (optionValue === 'price') {
        return data.currentData().sort((a, b) => a.price - b.price);
      } else if (optionValue === 'comment_count') {
        return data.currentData().sort((a, b) => b.comment_count - a.comment_count);
      } else if (optionValue === 'rating') {
        return data.currentData().sort((a, b) => b.rating - a.rating);
      }
    }
  }

  export default DataSorter;
