import React from 'react';

import Grid from '@mui/material/Grid';
import hospitalData from "../../Data/transplant_hospitals.json";
import { useParams } from "react-router";
import "./Hospital.css"
import PackageCard from '../../Components/PackageCard';
import HospitalDetail from '../HospitalDetail/HospitalDetail';

export default function Hospital() {
    const { hospitalId } = useParams();
    const hospitals = hospitalData
    const isSmallScreen = true
    const selectedHospital = hospitals.find(i => i.id == hospitalId)
    return (
        <div className='root'>
            <div className="container overflow-hidden my-3 bg-light shadow-lg rounded-3 no-padding">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} >
                        <HospitalDetail selectedHospital={selectedHospital} isSmallScreen={isSmallScreen} />
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}