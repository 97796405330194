import React from 'react';

function PriceRangeLabel({ price }) {
  let label = '';
  let bgColor = '';

  if (price != null) {
    if (price > 1800) {
      if (price < 2300) {
        label = '€€';
        bgColor = '#3498db'; // Blue background for moderate
      } else if (price > 3000) {
        label = '€€€€';
        bgColor = '#e74c3c'; // Red background for expensive
      } else {
        label = '€€€';
        bgColor = '#e74c3c'; // Red background for high-end
      }
    } else {
      label = '€';
      bgColor = '#27ae60'; // Green background for affordable
    }
  } else {
    label = 'Price not available';
    bgColor = '#95a5a6'; // Gray background for unavailable price
  }

  const style = {
    backgroundColor: bgColor,
    padding: '5px', // Add padding for better visibility
    color: 'white', // Text color is white for better contrast
    borderRadius: '10px', // Rounded corners,
    fontSize: '14px', // Smaller text size
  };

  return <text style={style}>{label}</text>;
}

export default PriceRangeLabel;
