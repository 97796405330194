import React from "react";
import { Link } from "react-router-dom";
import "./Category.css"
const Category = (props) => {
  const { name,color, pic, active } = props.category;
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 category-container" >
        <Link to="/services" className={active && "text-decoration-none category-text card category-card" || "text-decoration-none category-text disabled-link card category-card"} style={{ "background": `${color}, center/100% url(${pic}) no-repeat`}}>
          <div className="card-body flex">
            <h4 className="card-title text-center align-content">{name}</h4>
            {!active && <h6 className="card-title text-center align-content">(Soon)</h6>}
          </div>
          </Link>
        </div>      
    </>
  );
};

export default Category;
