import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./Pages/Header/Header";
import Footer from "./Pages/Footer/Footer";
import Information from "./Pages/Information/Information";
import Services from "./Pages/Services/Services";
import Hospital from "./Pages/Hospital/Hospital";
import About from "./Pages/Home/About/About";
import Categories from "./Pages/Categories/Category";
import "@fontsource/inter"; // Defaults to weight 400.

function App() {
  return (
    <div className="App">
      {/* <AuthProvider> */}
      <Router>
        <Header></Header>
        <Routes>
          <Route exact path="/" element={<Categories />}>
          </Route>
          <Route path="/home" element={<Categories />}>
          </Route>
          <Route path="/about" element={<About />}>
          </Route>
          <Route path="/services" element={<Services />}>
          </Route>
          <Route path="/info" element={<Information />}>
          </Route>
          <Route path="/hospital/:hospitalId" element={<Hospital />}>
          </Route>
          <Route exact path="/categories" element={<Categories />}>
          </Route>
        </Routes>
        <Footer></Footer>
      </Router>
      {/* </AuthProvider> */}
    </div>
  );
}

export default App;
