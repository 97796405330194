import React from 'react';
import "./css/PackageCard.css"

function PackageCard({ packageName, price, color, include }) {
    const listItemStyle = {
        listStyleType: 'none', // Remove bullets or numbering
      };
  return (
    <div className="package-card" style={{ backgroundColor: color, fontSize: '14px' }}>
      <h6>{packageName}</h6>
      <b>€{price}</b>
      <p className='no-btm-margin'><ul style={{ paddingInlineStart: '0', ...listItemStyle }}>
        {include.map((item, index) => (
          <li key={index} style={listItemStyle}>
            {item}
          </li>
        ))}
      </ul>
      </p>
      
    </div>
  );
}


export default PackageCard;