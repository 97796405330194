import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/material/Rating';
import hospitals from "../../Data/transplant_hospitals.json";
import { Pagination, Divider } from '@mui/material';
import usePagination from "../../Common/Pagination";
import { useNavigate } from "react-router-dom";
import "./Services.css";
import AvatarGenerator from '../../Helpers/AvatarGenerator';
import useSortAndStore from '../../Helpers/SortAndStore';
import DataSorter from '../../Helpers/DataSorter';
import HospitalDetail from '../HospitalDetail/HospitalDetail';

export default function Services() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobileWidth = width <= 993;

  const navigate = useNavigate();


  let [page, setPage] = useState(1);
  const PER_PAGE = height > 900 && isMobileWidth ? parseInt(height / 100) : 16;

  const count = Math.ceil(hospitals.length / PER_PAGE);
  const _DATA = usePagination(hospitals, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const { selectedSortOption, handleSortOptionChange } = useSortAndStore('rating');

  const sortedData = DataSorter.sortData(selectedSortOption, _DATA);

  const [selectedHospital, setSelectedHospital] = useState(sortedData[0]);

  return (
    <div className='root'>
      <div className="container overflow-hidden my-3 bg-light shadow-lg rounded-3 no-padding">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={isMobileWidth ? 12 : 4}>
            <Paper className='m-paper' style={!isMobileWidth ? { height: '1200px' } : {}}>
              <div className="left-small-header"><small>{hospitals.length} Best Clinics in Türkiye</small></div>
              <FormControl className='select-sort' size='small'>
                <Select
                  size='small'
                  disableUnderline={true}
                  className='light-text'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSortOption}
                  label="Sort by"
                  onChange={handleSortOptionChange}
                  variant="standard">
                  <MenuItem size='small' value='price'>Price Sort</MenuItem>
                  <MenuItem size='small' value='comment_count'>Comment Sort</MenuItem>
                  <MenuItem size='small' value='rating'>Stars Sort</MenuItem>
                </Select>
              </FormControl>
              <List id='hospitalList' >
                {sortedData.map((hospital, index) => (
                  <ListItemButton
                    key={index}
                    sx={{ width: '100%' }}
                    alignItems="center"
                    style={{ paddingRight: 0, paddingLeft: 0, paddingBottom: 5 }}
                    selected={selectedHospital && selectedHospital.id === hospital.id}
                    button
                    onClick={() => isMobileWidth ? navigate("/hospital/" + hospital.id) : setSelectedHospital(hospital)}>
                    {hospital.logo != null &&
                      <ListItemAvatar>
                        <img src={"./logos/" + hospital.logo} className='hospital-logo-dft' />
                      </ListItemAvatar>
                      ||
                      <Avatar {...AvatarGenerator.stringAvatar(hospital.name)} className='avatar'></Avatar>
                    }
                    <ListItemText primaryTypographyProps={{ fontSize: '15px' }} secondaryTypographyProps={{ fontSize: '13px' }} primary={hospital.name} secondary={hospital.province} />
                    <div className='lst-rating'>
                      <Rating max={1} size="small" value={hospital.rating} readOnly />
                      <p />
                      <small className='rating-text'> &nbsp;{hospital.rating == 5 ? '5.0' : hospital.rating} </small>
                      <small className='rating-text'> &nbsp;({hospital.comment_count})</small>
                    </div>
                  </ListItemButton>
                ))}
              </List>
              {isMobileWidth && (<><br /><br /></>)}
              <Pagination count={count} onChange={handleChange} size="small" className='pagination' />
            </Paper>
          </Grid>
          {!isMobileWidth ?
            <Grid item xs={12} sm={8} >
              <HospitalDetail selectedHospital={selectedHospital} />
            </Grid>
            : null}
        </Grid>
      </div>
    </div >
  );
}
