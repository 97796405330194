import React from "react";
import img2 from "../../../Images/About/find.jpg";

const About = () => {
  return (
    <div>
      <div className="container overflow-hidden text-center">
            <img src={img2} alt="" height="300" className ='padding-top' />
      </div>

      <div className="container my-5 d-flex flex-column align-items-center">
        <h5 className="w-75 text-center">We help you by finding the best hospitals on the Internet. If you have any question or complaint, share with us!</h5>
        <h6 className="w-75 text-center">info@hospifinder.com</h6>

      </div>
    </div>
  );
};

export default About;
