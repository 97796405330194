
import React, { useState, useEffect } from 'react';
import "./Information.css";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import img2 from "../../Images/planting.png";


export default function Information() {

    return (
        <div className='root'>
            <div className="container overflow-hidden my-3 bg-light shadow-lg rounded-3 no-padding">
            <div className="text-center">
            <img src={img2} alt="" height="80" className ='pad-top-15' />
            <h4 className='info-header'>Hair Transplant Techniques</h4>
            </div>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} >
                        <Paper className='paper'style={{ position: "relative" }}>
                          <div>
                            <main>
                              <section>
                                <h5 className='pad-t-15'>Follicular Unit Transplantation (FUT)</h5>
                                <p>FUT, also known as the strip method, is the traditional method of hair transplantation. In this technique, a strip of skin is removed from the donor area, usually at the back of the scalp. This strip is then dissected into individual follicular units, which are then transplanted into the recipient area. The advantage of this method is that it can yield a large number of grafts in a single session, making it suitable for patients with significant hair loss.</p>
                                <p>However, the downside of FUT is that it leaves a linear scar at the donor site, which can be visible if the hair is cut too short. Additionally, the recovery time can be longer than other techniques, as the wound at the donor site takes time to heal.</p>
                              </section>
                              <section>
                                <h5 className='pad-t-15'>Follicular Unit Extraction (FUE)</h5>
                                <p>FUE is a newer technique that involves extracting individual hair follicles directly from the scalp, rather than removing a strip of skin. This is done using a specialized punch tool that removes each follicle individually. The advantage of FUE is that it does not leave a linear scar, making it suitable for patients who prefer shorter hairstyles.</p>
                                <p>However, FUE can be more time-consuming than FUT, as each follicle must be extracted individually. This can also limit the number of grafts that can be harvested in a single session.</p>
                              </section>
                              <section>
                                <h5 className='pad-t-15'>Direct Hair Implantation (DHI)</h5>
                                <p>DHI is a newer technique that combines elements of FUE and FUT. In this technique, individual hair follicles are extracted using a specialized punch tool and then implanted directly into the recipient area using a Choi implanter pen. This allows for precise placement of the follicles, resulting in a natural-looking hairline.</p>
                                <p>The advantage of DHI is that it does not require the creation of incisions, which can minimize scarring and reduce recovery time. Additionally, it allows for precise placement of each hair follicle, resulting in a more natural-looking outcome.</p>
                              </section>
                              <section>
                                <h5 className='pad-t-15'>Robotic Hair Transplantation</h5>
                                <p>Robotic hair transplantation is a newer technique that uses a robotic arm to extract and implant hair follicles. The robot is programmed to identify and extract individual follicles from the donor area, and then implant them in the recipient area using a specialized tool. The advantage of robotic hair transplantation is that it allows for precise and consistent placement of hair follicles, resulting in a natural-looking outcome.</p>
                                <p>However, robotic hair transplantation can be more expensive than other techniques, and may not be covered by insurance.</p>
                              </section>
                            </main>
                          </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}

