import React from "react";
import "./Footer.css";
import logo from "../../Images/logo.png";

const Footer = () => {
  return (
    <div className="footer text-center text-white">
      {/* <img src={logo} alt="" width="180" height="50" /> */}
      <div className="container mb-3">
        <h5 style={{margin:0}}>HOSPIFINDER</h5>
        <small><small>2023</small></small>
        <br/>
        <text style={{fontSize:12.5, display:'block', lineHeight: 1}}>Please note that the hospitals listed on our website are based on a Google search and we do not take any responsibility for their services or actions. If the owner of any hospital listed on our website wishes to have their hospital removed from our system, they can contact us via email and we will promptly remove it. We advise all users to do their own research and due diligence before choosing a hospital for their medical needs.</text>
        <div className="pad-t-15"/>
        <i className="fab fa-facebook-square mx-4"></i>
        <i className="fab fa-twitter mx-4"></i>
        <i className="fab fa-instagram mx-4"></i>
        <i className="fab fa-linkedin-in mx-4"></i>
      </div>
    </div>
  );
};

export default Footer;
