import React from "react";
import Category from "../Home/Category/Category";
import categories from "../../Data/Categories.json";

const Categories = () => {
  return (
    <div className="my-3" style={{paddingTop: '20px'}}>
      <div className="container" >
        <div className="row g-3">
          {categories.map((c) => (
            <Category key={c.id} category={c}></Category>
          ))}
        </div>
        <br/><br />
        <h6 className="text-center">Find best clinics in Turkey with the best price.</h6>
        <h6 className="text-center">List hair transplant clinics in Turkey. Compare clinics with their prices.</h6>
        <h6 className="text-center">See the hair transplant package prices.</h6>
      </div>
    </div>
  );
};

export default Categories;
