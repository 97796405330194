import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import whatsapp from "../../Images/whatsapp.svg";
import instagram from "../../Images/instagram.svg";
import pin from "../../Images/pin.svg";
import van from "../../Images/van.svg";
import hotel from "../../Images/hotel.png";
import google from "../../Images/google.svg";
import arrow from "../../Images/arrow.svg";
import { Divider } from '@mui/material';
import "./HospitalDetail.css";
import PriceRangeLabel from '../../Components/PriceRangeLabel';
import PackageCard from '../../Components/PackageCard';


const HospitalDetail = ({ selectedHospital, isSmallScreen }) => {
  const facilitiesStyle = {
    inline: true,
    className: 'left-header-text pad-t-12',
    ...(isSmallScreen && {
      position: 'absolute',
      marginTop: '60px',
    }),
  };
  
  const pageHeight = !isSmallScreen?'1200px':'100%'
  return (
    <Paper className='m-paper main-logo' style={{ height: pageHeight, backgroundSize: selectedHospital.logoSize, backgroundImage: 'url(../../logos/' + selectedHospital.logo + ')' }}>
      {selectedHospital && (
        <>
          <b style={selectedHospital.logo && { display: 'none'}}>{selectedHospital.name.toUpperCase()}</b> 
          <br/>
          <div className='flex-row center-flex-i pad-t-5'>
            <Rating size="small" value={selectedHospital.rating} readOnly className='mar-bot-10'/> <small className='rating-text'>&nbsp; {selectedHospital.rating == 5 ? '5.0' : selectedHospital.rating}</small>
            <small><b>({selectedHospital.comment_count})</b></small>
          </div>
          <Typography inline variant="subtitle1" className='left-header-text pad-t-5'>
            <PriceRangeLabel price={selectedHospital.price} />
          </Typography>
          <Typography variant="subtitle2" className='right-header-text'>
            {!selectedHospital.nowp &&
              <Link target="_blank" href={selectedHospital.hphone} className="btn link pad-r-0">
                <text className='light-text'>{selectedHospital.phone}</text> <img src={whatsapp} width="24" height="24" />
              </Link>
              || <text className='btn link disabled-link'>{selectedHospital.phone}</text>
            }
          </Typography>
          <Typography inline variant="subtitle1" className='left-header-text pad-t-10'>
            {selectedHospital.technic && (<div className='left-icn-padding'><small className='light-text'>{selectedHospital.technic}</small></div>)}
          </Typography>
          {!isSmallScreen &&
          <>
          <Typography variant="subtitle2" className='right-header-text'>
            <Link target="_blank" href={selectedHospital.website} className="btn link">
              <text className='light-text'>Visit Website</text> <img src={arrow} width="15" height="24" />
            </Link>
          </Typography>
          {(selectedHospital.van_transportation || selectedHospital.hotel) &&
            <>
              {selectedHospital.van_transportation && selectedHospital.hotel && <><br /><br /></>}
              <Typography {...facilitiesStyle}>
                {selectedHospital.van_transportation && (<div><img src={van} width="32" height="32" /> <span className='s-text'>Transport</span></div>)}
                {selectedHospital.hotel && (<><div className='pl-5'><img src={hotel} width="16" height="16" /><span className='s-text pad-l-10'>Hotel</span></div></>)}
              </Typography>
              <br />
            </>
          }
          </>
          }
          {isSmallScreen &&
          <>
          {(selectedHospital.van_transportation || selectedHospital.hotel) &&
            <>
              {selectedHospital.van_transportation && selectedHospital.hotel && <><br /></>}
              <Typography variant="subtitle2" className='right-header-text'>
                {selectedHospital.van_transportation && (<div><img src={van} width="32" height="32" /><span className='s-text'>Transport</span></div>)}
                {selectedHospital.hotel && (<><div className='pl-5'><img src={hotel} width="16" height="16" /><span className='s-text pad-l-10'>Hotel</span></div></>)}
              </Typography>
              <br />
            </>
          }          
          </>
          }

          <div dangerouslySetInnerHTML={{ __html: selectedHospital.description }} className='description-txt'></div>

          {selectedHospital.packages &&
            <>
          <br/>
          <h6 className='section-header'>Samples</h6>
          <Divider className='divider' />
          <img src={"../../samples/" + selectedHospital.samples} className='hospital-samples-dft max-w-100 pad-t-15' />
          <br /><br />
          </>
          }

          {selectedHospital.packages &&
            <>
              <h6 className='section-header'>{selectedHospital.name} prices and packages</h6>
              <Divider className='divider' />
              <div className="package-component">
                {selectedHospital.packages.map((packageData, index) => (
                  <PackageCard
                    key={index}
                    packageName={packageData.packageName}
                    price={packageData.price}
                    color={packageData.color}
                    include={packageData.include}
                  />
                ))}
              </div>
              <p className='small-txt'>(Prices are sourced from the internet; no liability for inaccuracies.)</p>
            </>
          }

          
          {selectedHospital.analysis_link &&
            <>
              <Button variant='text' className='center' color='success' target="_blank" href={selectedHospital.analysis_link}>Get free analysis by {selectedHospital.name}</Button>
              <br /><br />
            </>
          }
        </>
      )}
      <div className={!isSmallScreen ? 'btm-info' : 'h-btm-info'}>
      {isSmallScreen &&
        <div>
            <Link target="_blank" href={selectedHospital.website} className="btn link">
              <text className='light-text'>Visit Website</text> <img src={arrow} width="15" height="24" />
            </Link>
        </div>
      }
        <div>
          <img src={instagram} width="35" height="35" /> <small><Link target="_blank" href={selectedHospital.instagram_link}> {selectedHospital.instagram} </Link></small>
        </div>
        <div className='info-el'>
          <img src={google} width="35" height="30" /> <small>{selectedHospital.rating}/5 from <Link target="_blank" href={selectedHospital.google_link}> {selectedHospital.comment_count} comments </Link></small>
        </div>
        <div className='info-el' >
          <img src={pin} width="35" height="30" /><small> {selectedHospital.to_airport}</small>
        </div>
      </div>
    </Paper>
  );
};

export default HospitalDetail;
