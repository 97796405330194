import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../Images/logo.png";

const Header = () => {

  const activeStyle = {
    fontWeight: "bold",
    color: "rgb(117 117 117)"
  };

  return (
    <>
      <Navbar bg="light" variant="light" expand="lg" sticky="top">
        <Container varient="light">
          <Navbar.Brand as={NavLink} to="/home">
            <img src={logo} alt="Healthcare" width="150" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto fs-5" style={{paddingRight:'100px'}}>
              <Nav.Link as={NavLink} activeStyle={activeStyle} to="/categories">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} activeStyle={activeStyle} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={NavLink} activeStyle={activeStyle} to="/info">
                Techniques
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
